import React, { useEffect, useState } from 'react';

import {
    Image
} from 'react-bootstrap';

import { isMobile } from 'react-device-detect';

import image_1 from '../../assets/images/1.jpg';
import image_l_2_1 from '../../assets/images/l_2.1.jpg';
import image_r_2_2 from '../../assets/images/r_2.2.jpg';
import image_r_2_3 from '../../assets/images/r_2.3.jpg';
import image_l_2_4 from '../../assets/images/l_2.4.jpg';
import image_l_2_5 from '../../assets/images/l_3.1.jpg';
import image_r_2_6 from '../../assets/images/r_3.2.jpg';
import image_4 from '../../assets/images/4.jpg';
import PortraitImageCycler from './PortraitImageCycler';
import LandscapeImageCycler from './LandscapeImageCycler';

const BackgroundImageCycler = () => {
    const [imageCycler, setImageCycler] = useState(null);

    const images = [
        {
            name: 'image_1',
            type: 'landscape',
            image: image_1
        },
        {
            name: 'image_l_2_1',
            type: 'portrait_lg',
            image: image_l_2_1
        },
        {
            name: 'image_r_2_2',
            type: 'portrait_sm',
            image: image_r_2_2
        },
        {
            name: 'image_r_2_3',
            type: 'portrait_lg',
            image: image_r_2_3
        },
        {
            name: 'image_l_2_4',
            type: 'portrait_sm',
            image: image_l_2_4
        },
        {
            name: 'image_l_2_5',
            type: 'portrait_lg',
            image: image_l_2_5
        },
        {
            name: 'image_r_2_6',
            type: 'portrait_sm',
            image: image_r_2_6
        },
        // {
        //     name: 'image_l_3_1',
        //     type: 'portrait_lg',
        //     image: image_l_3_1
        // },
        // {
        //     name: 'image_r_3_2',
        //     type: 'portrait_sm',
        //     image: image_r_3_2
        // },
        {
            name: 'image_4',
            type: 'landscape',
            image: image_4
        }
    ];

    const portraitSequence1 = [
        'l_2_1',
        'r_2_2',
        'r_2_3',
        'l_2_4',
        'l_2_5',
        'r_2_6'
    ];

    const portraitSequence2 = [
        'l_3_1',
        'r_3_2'
    ];

    let imageMapIndex = 0;

    useEffect(() => {
        setImageCycler(isMobile ? imageMapMobile[imageMapIndex] : imageMap[imageMapIndex]);
    }, []);

    const cycleNextBackground = () => {
        imageMapIndex++;

        setImageCycler(null);
        setImageCycler(isMobile ? imageMapMobile[imageMapIndex] : imageMap[imageMapIndex]);

        if(imageMapIndex >= (isMobile ? imageMapMobile.length : imageMap.length) - 1) {
            imageMapIndex = -1;
        }
    };

    const imageMap = [
        (
            <div className="image-cycler-container landscape-image-container landscape-1">
                <LandscapeImageCycler className="image" _image={images[0].image} cycleNextBackground={cycleNextBackground} />
            </div>
        ),
        (
            <div className="image-cycler-container portrait-image-container portrait-2-container">
                <PortraitImageCycler _images={images.filter(image => image.name.includes('_2_'))} sequence={portraitSequence1} cycleNextBackground={cycleNextBackground} />
            </div>
        ),
        // (
        //     <div className="image-cycler-container portrait-image-container portrait-3-container">
        //         <PortraitImageCycler _images={images.filter(image => image.name.includes('_3_'))} sequence={portraitSequence2} cycleNextBackground={cycleNextBackground} />
        //     </div>
        // ),
        (
            <div className="image-cycler-container landscape-image-container landscape-2">
                <LandscapeImageCycler className="image" _image={images[images.length - 1].image} cycleNextBackground={cycleNextBackground} />
            </div>
        )
    ];

    const imageMapMobile = images.map((image, index) => {
        return (
            <div className={`image-cycler-container landscape-image-container landscape-${index + 1}`}>
                <LandscapeImageCycler className="image" _image={image.image} cycleNextBackground={cycleNextBackground} />
            </div>
        );
    });

    return (
        <div id="background-image-cycler-container">
            <div id="background-image-cycler">
                {imageCycler}
            </div>
        </div>
    );
};

export default BackgroundImageCycler;