import React from 'react';

import Header from '../Header';

import '../../css/menu.css';

const Menu = () => {
    return (
        <div id="menu">
            <Header />

            <footer id="footer">
                &copy; {(new Date()).getFullYear()} Styled Habitat. All rights reserved.
            </footer>
        </div>
    );
};

export default Menu;