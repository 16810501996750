import React, { useState, useEffect } from 'react';

import {
    Image
} from 'react-bootstrap';

const LandscapeImageCycler = ({ _image, cycleNextBackground }) => {
    const [image, setImage] = useState(_image);
    const [fadeIn, setFadeIn] = useState(true);

    useEffect(() => {
        const fadeTimeout = setTimeout(() => {
            setFadeIn(false);

            const timeout = setTimeout(() => {
                cycleNextBackground();
            }, 5000);
            
            // clearTimeout(fadeTimeout);
            // clearTimeout(timeout);
        }, 5000);
    }, []);

    return (
        <div className="landscape-image-cycler">
            <div className="landscape-image-container image-container">
                <Image className={`image ${fadeIn ? 'toggleIn' : 'toggleOut'}`} src={image} />
            </div>
        </div>
    );
};

export default LandscapeImageCycler;