import React, { useEffect, useState } from 'react';
import './App.css';
import './css/splash-screen.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import SplashScreen from './components/SplashScreen';
import Menu from './components/Menu';
import Connect from './components/Connect';
import BackgroundImageCycler from './components/BackgroundImageCycler';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import image_1 from './assets/images/1.jpg';
import image_l_2_1 from './assets/images/l_2.1.jpg';
import image_r_2_2 from './assets/images/r_2.2.jpg';
import image_r_2_3 from './assets/images/r_2.3.jpg';
import image_l_2_4 from './assets/images/l_2.4.jpg';
import image_l_2_5 from './assets/images/l_3.1.jpg';
import image_r_2_6 from './assets/images/r_3.2.jpg';
import image_4 from './assets/images/4.jpg';

const App = () => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [start, setStart] = useState(false);

  const images = [
    image_1,
    image_l_2_1,
    image_r_2_2,
    image_r_2_3,
    image_l_2_4,
    image_l_2_5,
    image_r_2_6,
    image_4
  ];

  useEffect(() => {
    images.forEach((image) => {
      new Image().src = image;
    });

    setTimeout(() => {
      setShowSplashScreen(false);
      setStart(true);
    }, 3000);
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route
            exact
            path="/"
            render={props => (
              <div className="App">
                {
                  start && <Menu />
                }
        
                {
                  showSplashScreen && <SplashScreen />
                }
        
                {
                  start && <BackgroundImageCycler />
                }
              </div>
            )}
        />

        <Route
            exact
            path="/connect"
            render={props => (
                <Connect />
            )}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
