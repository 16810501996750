import React, { useState, useEffect } from 'react';

import {
    Image
} from 'react-bootstrap';

const PortraitImageCycler = ({ _images, sequence, cycleNextBackground }) => {
    const [images, setImages] = useState(_images);
    const [lImage, setLImage] = useState(null);
    const [rImage, setRImage] = useState(null);

    const [lImageShow, setLImageShow] = useState(true);
    const [rImageShow, setRImageShow] = useState(false);

    const [lImageOpacity, setLImageOpacity] = useState(1);
    const [rImageOpacity, setRImageOpacity] = useState(0);

    let pauseInterval = false;

    useEffect(() => {
        console.log('Portrait cycler initiated', _images, sequence);
        let sequenceIndex = 0;

        setLImage(images.find(image => image.name.includes(sequence[sequenceIndex++])));

        (async() => await showImage('l', 0, true))();

        const interval = setInterval(async () => {
            console.log('Pause Interval', pauseInterval, new Date());
            if(!pauseInterval) {
                if(sequence[sequenceIndex].includes('l')) {
                    console.log(sequence[sequenceIndex]);

                    await hideImage('l');

                    console.log('set l image');
                    setLImage(null);
                    setLImage(images.find(image => image.name.includes(sequence[sequenceIndex])));

                    await showImage('l');
                } else {
                    console.log(sequence[sequenceIndex]);

                    await hideImage('r');

                    console.log('set r image');
                    setRImage(null);
                    setRImage(images.find(image => image.name.includes(sequence[sequenceIndex])));

                    await showImage('r');
                }
        
                if(sequenceIndex++ >= sequence.length - 1) {
                    sequenceIndex = 0;
                    
                    setTimeout(() => {
                        cycleNextBackground();
                        clearInterval(interval);
                    }, 1000);
                }
            }
        }, 3000);

        return null;
    }, []);

    const opacityDuration = 30;
    const opacityDelta = 0.01;

    const showImage = async (position, imageOpacity = 0, init = false) => {
        pauseInterval = init ? false : true;

        if(imageOpacity >= 1) {
            if(!init) await sleep(2000);
            
            pauseInterval = false;
            return;
        }

        if(position === 'l') {
            const newImageOpacity = imageOpacity + opacityDelta;

            setLImageOpacity(newImageOpacity);

            await sleep(opacityDuration);
            await showImage(position, newImageOpacity, init);
        } else {
            const newImageOpacity = imageOpacity + opacityDelta;

            setRImageOpacity(newImageOpacity);

            await sleep(opacityDuration);
            await showImage(position, newImageOpacity, init);
        }
    };

    const hideImage = async (position, imageOpacity = 1) => {
        pauseInterval = true;
        
        if(imageOpacity <= 0) {
            await sleep(2000);
            
            pauseInterval = false;
            return;
        }

        if(position === 'l') {
            const newImageOpacity = imageOpacity - opacityDelta;

            setLImageOpacity(newImageOpacity);

            await sleep(opacityDuration);
            await hideImage(position, newImageOpacity);
        } else {
            const newImageOpacity = imageOpacity - opacityDelta;

            setRImageOpacity(newImageOpacity);

            await sleep(opacityDuration);
            await hideImage(position, newImageOpacity);
        }
    };

    const sleep = delay => {
        return new Promise( res => setTimeout(res, delay) );
    }

    //${lImageShow ? 'toggleIn' : 'toggleOut'} 
    const lImageClassName = `${lImage && lImage.type === 'portrait_sm' ? 'image-sm' : 'image-lg'} image`;

    //${rImageShow ? 'toggleIn' : 'toggleOut'} 
    const rImageClassName = `${rImage && rImage.type === 'portrait_sm' ? 'image-sm' : 'image-lg'} image`;

    return (
        <div className="portrait-image-cycler"> 
            <div className={lImage && lImage.type === 'portrait_sm' ? "image-container-sm image-container l-image" : "image-container-lg image-container l-image"}>
                <Image className={lImageClassName} style={{
                    opacity: lImageOpacity
                }} src={lImage && lImage.image ?
lImage.image : ''} />
            </div>

            <div className={rImage && rImage.type === 'portrait_sm' ? "image-container-sm image-container r-image" : "image-container-lg image-container r-image"}>
                <Image className={rImageClassName} style={{
                    opacity: rImageOpacity
                }} src={rImage && rImage.image ? rImage.image : ''} />
            </div>
        </div>
    );
};

export default PortraitImageCycler;