import React from 'react';

import { IoLogoInstagram } from 'react-icons/io';
import { IoHomeOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import '../../css/header.css';

const Header = ({ page }) => {
    return (
        <div id="header" style={{
            color: 'black'
        }}>
            {
                page === 'connect'
                &&
                <div className="header-item">
                    <Link to="/" style={{
                        color: page === 'connect' ? 'black' : 'white'
                    }}>
                        <IoHomeOutline className="header-item ig-icon" style={{
                            color: page === 'connect' ? 'black' : 'white'
                        }} />
                    </Link>
                </div>
            }

            <a target="_blank" href="https://www.instagram.com/styledhabitat/?hl=en">
                <IoLogoInstagram className="header-item ig-icon" style={{
                    color: page === 'connect' ? 'black' : 'white'
                }} />
            </a>
            
            <div className="header-item">
                <Link to="/connect" style={{
                    color: page === 'connect' ? 'black' : 'white'
                }}>
                    CONNECT
                </Link>
            </div>
        </div>
    );
};

export default Header;