import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../css/button.css";

class Button extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: "Button",
            url: "",
            background_color: "white",
            text_color: "black",
            border_color: "black",
            is_external: false
        };
    }

    componentDidMount() {
        console.log("TITLE " + this.props.title);

        this.setState({
            title: this.props.title,
            url: this.props.url,
            data: this.props.data,
            background_color:
                this.props.background_color !== ""
                    ? this.props.background_color
                    : "white",
            border_color:
                this.props.border_color !== ""
                    ? this.props.border_color
                    : "black",
            text_color:
                this.props.text_color !== "" ? this.props.text_color : "black",
            is_external: this.props.is_external
        });
    }

    componentWillReceiveProps(newProps) {
        this.props = newProps;

        console.log("TITLE " + this.props.title);

        this.setState({
            title: this.props.title,
            url: this.props.url,
            data: this.props.data,
            background_color:
                this.props.background_color !== ""
                    ? this.props.background_color
                    : "white",
            border_color:
                this.props.border_color !== ""
                    ? this.props.border_color
                    : "black",
            text_color:
                this.props.text_color !== "" ? this.props.text_color : "black",
            is_external: this.props.is_external
        });
    }

    render() {
        let aStyle = {
            // backgroundColor: this.state.background_color,
            // borderColor: this.state.border_color,
            // borderWidth: "1px",
            // borderStyle: "solid",
            backgroundColor: "transparent",
            backgroundImage:
                "linear-gradient(to left, " +
                this.state.border_color +
                " 20px, " +
                this.state.border_color +
                " 20px), linear-gradient(to bottom, " +
                this.state.border_color +
                " 28px, transparent 20px), linear-gradient(to right, " +
                this.state.border_color +
                " 188px, transparent 20px), linear-gradient(to top, " +
                this.state.border_color +
                " 20px, " +
                this.state.border_color +
                " 20px)",
            backgroundPosition: "100% 0, 100% 0, 0 100%, 0 100%",
            backgroundSize: "100% 1px, 1px 100%",
            backgroundRepeat: "no-repeat"
        };

        let spanStyle = {
            color: this.state.text_color
        };

        return !this.state.is_external ? (
            <Link
                className="sh-button"
                to={{
                    pathname: this.state.url,
                    data: this.state.data
                }}
                style={aStyle}
            >
                <button className="sh-button-content-container">
                    <span className="sh-button-content" style={spanStyle}>
                        {this.state.title}
                    </span>
                </button>
            </Link>
        ) : (
            <a
                className="sh-button"
                href={this.state.url}
                style={aStyle}
                target="_blank"
            >
                <button className="sh-button-content-container">
                    <span className="sh-button-content" style={spanStyle}>
                        {this.state.title}
                    </span>
                </button>
            </a>
        );
    }
}

export default Button;
