import React, { Component } from 'react';
import $ from 'jquery';

import '../../css/connect.css';
import Button from '../Button';
import Header from '../Header';

class Connect extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    document.title = 'Connect | Styled Habitat';
  }

  componentWillReceiveProps(newProps) {
    this.props = newProps;
  }

  formSubmitted() {
    const API_KEY = 'api-602C22B2E80D11ED9893F23C91BBF4A0';
    const URL = 'https://api.smtp2go.com/v3';

    const name = $('#name-field').val();
    const location = $('#location-field').val();
    const email = $('#email-field').val();
    const hear = $('#hear-field').val();
    const message = $('#message-text-area').val();

    let is_invalid = true;

    if (!name) {
      $('#name-field').addClass('invalid');
      is_invalid = true;
    } else {
      $('#name-field').removeClass('invalid');
      is_invalid = false;
    }

    if (!location) {
      $('#location-field').addClass('invalid');
      is_invalid = true;
    } else {
      $('#location-field').removeClass('invalid');
      is_invalid = is_invalid ? true : false;
    }

    if (!email) {
      $('#email-field').addClass('invalid');
      is_invalid = true;
    } else {
      $('#email-field').removeClass('invalid');
      is_invalid = is_invalid ? true : false;
    }

    if (!hear) {
      $('#hear-field').addClass('invalid');
      is_invalid = true;
    } else {
      $('#hear-field').removeClass('invalid');
      is_invalid = is_invalid ? true : false;
    }

    if (!message) {
      $('#message-text-area').addClass('invalid');
      is_invalid = true;
    } else {
      $('#message-text-area').removeClass('invalid');
      is_invalid = is_invalid ? true : false;
    }

    if (
      !is_invalid
      // name !== "" &&
      // location !== "" &&
      // email !== "" &&
      // hear !== "" &&
      // message !== ""
    ) {
      let from = email;
      let subject = 'CONNECT';
      let _message = `<b>Name:</b><br>${name}<br><br>`;
      _message += `<b>Email:</b><br>${from}<br><br>`;
      _message += `<b>Location:</b><br>${location}<br><br>`;
      _message += `<b>How did you hear about us?</b><br>${hear}<br><br>`;
      _message += `<b>Message:</b><br>${message}<br><br>`;

      let url = `${URL}/email/send`; //`https://us-central1-styledhabitat-dev.cloudfunctions.net/sendMail?from=${from}&subject=${subject}&message=${_message}`;

      // fetch(encodeURI(url))
      // .then(response => {
      //     let status = response.status;

      //     if(status == 200) {
      //         alert("Thank you. We will get back to you shortly.");
      //     } else {
      //         alert("Could not send email. Please contact us at info@styledhabitat.com");
      //     }
      // });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_key: API_KEY,
          sender: 'Info <info@styledhabitat.com>',
          to: ['Info <info@styledhabitat.com>'],
          subject,
          html_body: _message,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          let succeeded = json.data.succeeded;

          if (succeeded >= 1) {
            alert('Thank you. We will get back to you shortly.');
          } else {
            alert(
              'Could not send email. Please contact us at info@styledhabitat.com'
            );
          }
        })
        .catch((error) => {
          alert(
            'Could not send email. Please contact us at info@styledhabitat.com'
          );
          console.error(error);
        });
    }
  }

  render() {
    return (
      <div className="connect-root">
        <Header page="connect" />
        <div className="connect-form">
          <form>
            <h1 className="container connect-form-title">CONNECT</h1>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <input
                  id="name-field"
                  type="text"
                  className="form-control"
                  placeholder="NAME"
                />
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12">
                <input
                  id="location-field"
                  type="text"
                  className="form-control"
                  placeholder="LOCATION"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <input
                  id="email-field"
                  type="text"
                  className="form-control"
                  placeholder="EMAIL"
                />
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <input
                  id="hear-field"
                  type="text"
                  className="form-control"
                  placeholder="HOW DID YOU HEAR ABOUT US?"
                />
              </div>
            </div>

            <div className="row">
              <textarea
                className="form-control"
                id="message-text-area"
                rows="3"
                placeholder="MESSAGE"
              />
            </div>
          </form>
          <div className="send-button-container">
            <button
              style={{
                backgroundColor: 'transparent',
                backgroundImage:
                  'linear-gradient(to left, white 20px, white 20px),' +
                  'linear-gradient(to bottom, white 28px, transparent 20px),' +
                  'linear-gradient(to right, white 188px, transparent 20px),' +
                  'linear-gradient(to top, white 20px, white 20px)',
                backgroundPosition: '100% 0, 100% 0, 0 100%, 0 100%',
                backgroundSize: '100% 1px, 1px 100%',
                backgroundRepeat: 'no-repeat',
              }}
              type="button"
              onClick={() => this.formSubmitted()}
            >
              SEND
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Connect;
