import React from 'react';
import {
    Image
} from 'react-bootstrap';

import logo from '../../assets/images/styled-habitat-splash-screen-logo.png';

import '../../css/background-image-cycler.css';

const SplashScreen = () => {
    return (
        <div id="splash-screen">
            <Image id="splash-screen-logo" src={logo} />
        </div>
    );
};

export default SplashScreen;